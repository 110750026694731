.app {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: stretch;
  justify-content: stretch;
  align-content: stretch;
  grid-template-columns: 200px 1fr 250px;
  grid-template-rows: auto 1fr 100px;
  grid-template-areas:
    'header-branding header header'
    'sidebar content content'
    'sidebar content content';
}

.header-branding {
  grid-area: header-branding;
  place-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(211, 211, 211, 0.3);
}

header {
  grid-area: header;
  display: flex;
  /*flex: 1;*/
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid rgba(211, 211, 211, 0.3);
}

.content {
  grid-area: content;
  overflow: scroll;
}

.text {
  /*text-align: center;*/
  max-width: 40em;
  padding: 12px;
  /*margin: 20px;*/
  line-height: 2em;
  /*font-size: 1.2em;*/
  border-bottom: 1px solid rgba(211, 211, 211, 0.3);
  /*border-radius: 10px;*/
}

.cleanser-logo {
  align-self: center;
  text-align: center;
  line-height: 0.3em;
  margin: 14px 10px 0 10px;
  /*margin-top: 10px;*/
  font-size: 2em;
  font-weight: bolder;
  color: red;
  transform: skew(-18deg) rotate(-5deg);
  /*-webkit-text-stroke: 1px #fcfcfc;*/
  /*paint-order: stroke fill;*/
}

.cleanser-logo > p {
  color: darkgray;
  font-size: 0.32em;
}

.segment {
  padding: 3px 0;
  margin: 2px 0;
}

.segment:hover {
  color: blue;
  cursor: pointer;
}

.segment.selected {
  color: orangered;
}

.segment.tagged {
  border: 1px solid lightblue;
  border-radius: 4px;

  padding: 3px 3px;
  margin: 2px 2px;
}

.tag {
  color: lightblue;
  user-select: none;
}

.search {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
}

.search-info {
  margin: 0 10px;
  font-size: 0.7em;
}

.search-input {
  background-color: #f7f7f7;
  outline: none;
  border: 1px solid #f9f9f9;
  border-radius: 8px;
  padding: 10px 20px;
  /*margin: 10px 0;*/
  font-size: 1.2em;
  /*display: flex;*/
  flex: 1;
  max-width: 30em;
}

.sidebar {
  grid-area: sidebar;
  padding: 10px
}